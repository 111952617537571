<template>
  <ul class="menu-nav">
    <!-- Iterate over menu sections -->
    <template v-for="(section, sectionIndex) in menuSections" >
      <!-- Section Header -->
      <li :key="sectionIndex" class="menu-section" v-if="!section.hide && section.name && (!section.condition || section.condition)">
        <h4 class="menu-text">{{ section.name }}</h4>
        <i class="menu-icon flaticon-more-v2"></i>
      </li>
      <!-- Iterate over items in the section -->
      <template v-for="(item, itemIndex) in section.items" >
       
        <router-link
          v-if="item && item.route && hasAccess(item.access)"
          :to="item.route"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="{ 'menu-item-active': isActive || isExactActive }"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <!-- Handle icon components or classes -->
              <span v-if="item.iconComponent" class="menu-icon">
                <component :is="item.iconComponent" v-bind="item.iconProps"></component>
              </span>
              <i v-else :class="['menu-icon', item.icon]"></i>
              <span class="menu-text">{{ item.text }}</span>
            </a>
          </li>
        </router-link>
      </template>
    </template>
  </ul>
</template>
<style lang="scss" scoped>
li:hover > a > span > svg,
.menu-item-active > a > span > svg {
  fill: #3699FF;
}
</style>

<script>

import CompanyNotes from '@/view/components/CompanyNotes';
import { mapGetters } from 'vuex';
import { hasAccessToPageForCurrentCompany } from '@/core/services/companyAccess';

export default {
  name: 'KTMenu',
  components: {
    CompanyNotes
  },
  computed: {
    ...mapGetters(['currentAccess','currentCompanyId', 'isTHS'])
  },
  data() {
    return {
      
      menuSections: [
        // Main Menu Section
        {
          name: null, // No header for main items
          hide: false,
          items: [
            {
              route: '/ml-onboarding-wizard',
              icon: 'flaticon-list',
              text: this.$t('AUTH.MENU.ONBOARDING_WIZARD'),
              access: 'ml-onboarding-wizard',
            },
            {
              route: '/ml-dashboard',
              icon: 'flaticon2-start-up',
              text: this.$t('AUTH.MENU.DASHBOARD'),
              access: 'ml-dashboard',
            },
            {
              route: '/ml-todo',
              icon: 'flaticon-list',
              text: this.$t('AUTH.MENU.TODO'),
              access: 'ml-todo',
            },
            {
              route: '/ml-errors',
              icon: 'flaticon-warning-sign',
              text: this.$t('AUTH.MENU.ERROR'),
              access: 'ml-errors',
            },
            {
              route: '/ml-events',
              icon: 'flaticon-presentation',
              text: this.$t('AUTH.MENU.EVENT'),
              access: 'ml-events',
            },
            {
              route: '/ml-reports',
              icon: 'flaticon-file',
              text: this.$t('AUTH.MENU.REPORTS'),
              access: 'ml-reports',
            },
          ],
        },
        // Members Section
        {
          name: this.$t('AUTH.MENU.MEMBERS'),
          condition: this.section('members'),
          hide: false,
          items: [
            {
              route: '/ml-members-profile',
              icon: 'flaticon-signs-1',
              text: this.$t('AUTH.MENU.MEMBERS_PROFILE'),
              access: 'ml-members-profile',
            },
            {
              route: '/ml-members-control',
              icon: 'flaticon-signs-1',
              text: this.$t('AUTH.MENU.MEMBERS_CONTROL'),
              access: 'ml-members-control',
            },
            {
              route: '/ml-advanced-search',
              icon: 'flaticon2-search',
              text: this.$t('AUTH.MENU.ADV_SEARCH'),
              access: 'ml-advanced-search',
            },
            {
              route: '/ml-duplicates',
              icon: 'flaticon-warning',
              text: this.$t('AUTH.MENU.DUPLICATES'),
              access: 'ml-duplicates',
            },
            {
              route: '/ml-spar',
              icon: 'flaticon-clipboard',
              text: this.$t('AUTH.MENU.SPAR'),
              access: 'ml-spar',
            },
            {
              route: '/ml-import',
              icon: 'flaticon-file-1',
              text: this.$t('AUTH.MENU.IMPORT'),
              access: 'ml-import',
            },
            {
              route: '/ml-stickprov',
              icon: 'flaticon2-phone',
              text: this.$t('AUTH.MENU.STICKPROV'),
              access: 'ml-stickprov',
            },
            {
              route: '/ml-ladok',
              icon: 'flaticon2-box-1',
              text: this.$t('AUTH.MENU.LADOK'),
              access: 'ml-ladok',
            },
            {
              route: '/ml-properties',
              icon: 'flaticon2-box-1',
              text: this.$t('AUTH.MENU.ATTRIBUTES'),
              access: 'ml-properties',
            },
            {
              route: '/ml-renew-page',
              icon: 'flaticon2-box-1',
              text: this.$t('AUTH.MENU.RENEWS'),
              access: 'ml-renew-page',
            },
            {
              route: '/ml-member-registrations',
              icon: 'flaticon-map',
              text: this.$t('AUTH.MENU.MEMBER_REGISTRATIONS'),
              access: 'ml-member-registrations',
            },
            {
              route: '/ml-payments',
              icon: 'flaticon2-box-1',
              text: this.$t('AUTH.MENU.ORDERS'),
              access: 'ml-payments',
            },
            {
              route: '/ml-invoices',
              icon: 'flaticon2-contract',
              text: this.$t('AUTH.MENU.INVOICES'),
              access: 'ml-invoices',
            },
            {
              route: '/ml-fortnox-invoices',
              icon: 'flaticon2-contract',
              text: this.$t('AUTH.MENU.FORTNOX_INVOICES'),
              access: 'ml-fortnox-invoices',
            },
            {
              route: '/ml-redeem-codes',
              icon: 'flaticon-gift',
              text: this.$t('AUTH.MENU.COUPONS'),
              access: 'ml-redeem-codes',
            },
            {
              route: '/ml-jobs',
              icon: 'flaticon2-contract',
              text: this.$t('AUTH.MENU.JOBS'),
              access: 'ml-jobs',
            },
          ],
        },
        // Association Section
        {
          name: 'FÖRENING',
          condition: this.section('association'),
          hide: false,
          items: [
            {
              route: '/ml-donations',
              icon: 'flaticon-coins',
              text: this.$t('AUTH.MENU.DONATIONS'),
              access: 'ml-donations',
            },
            {
              route: '/ml-grant',
              icon: 'flaticon-coins',
              text: this.$t('AUTH.MENU.GRANT'),
              access: 'ml-grant',
            },
            {
              route: '/ml-mucf',
              icon: 'flaticon-list-3',
              text: this.$t('AUTH.MENU.MUCF'),
              access: 'ml-mucf',
            },
            {
              route: '/ml-settings',
              icon: 'flaticon2-gear',
              text: this.$t('AUTH.MENU.SETTINGS'),
              access: 'ml-settings',
            },
            {
              route: '/ml-forms',
              icon: 'flaticon-app',
              text: this.$t('AUTH.MENU.FORMS'),
              access: 'ml-forms',
            },
            {
              route: '/ml-registersetup',
              icon: 'flaticon-app',
              text: this.$t('AUTH.MENU.REGISTERSETUP'),
              access: 'ml-registersetup',
            },
            {
              route: '/ml-users',
              icon: 'flaticon2-safe',
              text: this.$t('AUTH.MENU.USERS'),
              access: 'ml-users',
            },
            {
              route: '/ml-subcompany',
              icon: 'flaticon-map',
              text: this.$t('AUTH.MENU.SUBCOMPANY'),
              access: 'ml-subcompany',
            },
            {
              route: '/ml-faith',
              icon: 'flaticon-map',
              text: this.$t('AUTH.MENU.FAITH'),
              access: 'ml-faith',
            },
            {
              route: '/ml-importcompanies',
              icon: 'flaticon-map',
              text: this.$t('AUTH.MENU.IMPORTCOMPANIES'),
              access: 'ml-importcompanies',
            },
            {
              route: '/ml-kvitto',
              icon: 'flaticon-folder-1',
              text: this.$t('AUTH.MENU.KVITTO'),
              access: 'ml-kvitto',
            },
            {
              route: '/ml-files',
              icon: 'flaticon-folder-1',
              text: this.$t('AUTH.MENU.FILES'),
              access: 'ml-files',
            },
            {
              route: '/ml-shopitem',
              icon: 'flaticon-price-tag',
              text: this.$t('AUTH.MENU.SHOPITEM'),
              access: 'ml-shopitem',
            },
            {
              route: '/ml-book',
              icon: 'flaticon2-sheet',
              text: this.$t('AUTH.MENU.BOOK'),
              access: 'ml-book',
            },
            {
              route: '/ml-transactions',
              icon: 'flaticon2-tag',
              text: this.$t('AUTH.MENU.TRANSACTIONS'),
              access: 'ml-transactions',
            },
            {
              route: '/ml-education',
              icon: 'flaticon2-box-1',
              text: this.$t('AUTH.MENU.EDUCATION'),
              access: 'ml-education',
            },
            {
              route: '/ml-swish-merchant',
              icon: 'flaticon-coins',
              text: this.$t('AUTH.MENU.SWISH_MERCHANT'),
              access: 'ml-swish-merchant',
            },
          ],
        },
        // Communication Section
        {
          name: this.$t('AUTH.MENU.COMMUNICATION'),
          condition: this.section('communication'),
          hide: false,
          items: [
            {
              route: '/ml-sendout',
              icon: 'flaticon2-mail-1',
              text: this.$t('AUTH.MENU.SENDOUT'),
              access: 'ml-sendout',
            },
            {
              route: '/ml-sms',
              icon: 'flaticon2-mail-1',
              text: this.$t('AUTH.MENU.SMS'),
              access: 'ml-sms',
            },
            {
              route: '/ml-templates',
              icon: 'flaticon-interface-11',
              text: this.$t('AUTH.MENU.TEMPLATES'),
              access: 'ml-templates',
            },
          ],
        },
        // Administration Section
        {
          name: 'Administration',
          condition: this.section('admin'),
          hide: false,
          items: [
            {
              route: '/ml-billing',
              icon: 'flaticon-interface-9',
              text: this.$t('AUTH.MENU.BILLING'),
              access: 'ml-billing',
            },
          ],
        },
        // Super Admin Section
        {
          name: 'Super Admin',
          condition: this.isTHS,
          hide: false,
          items: [
            
            {
              route: '/ml-companies',
              icon: 'flaticon-open-box',
              text: this.$t('AUTH.MENU.COMPANIES'),
              access: 'ml-companies',
            },
            {
              route: '/ml-periods',
              icon: 'flaticon-clock-2',
              text: this.$t('AUTH.MENU.PERIODS'),
              access: 'ml-periods',
            },
            {
              route: '/ml-history',
              icon: 'flaticon-cogwheel',
              text: this.$t('AUTH.MENU.HISTORY'),
              access: 'ml-history',
            },
            {
              route: '/ml-system',
              icon: 'flaticon-cogwheel',
              text: this.$t('AUTH.MENU.SYSTEM'),
              access: 'ml-system',
            },
            {
              route: '/ml-redoc',
              icon: 'flaticon-book',
              text: this.$t('AUTH.MENU.REDOC'),
              access: 'ml-redoc',
            },
            {
              route: '/ml-apikeys',
              icon: 'flaticon-book',
              text: this.$t('AUTH.MENU.APIKEYS'),
              access: 'ml-apikeys',
            },
            {
              route: '/ml-page',
              icon: 'flaticon2-grids',
              text: this.$t('AUTH.MENU.PAGE'),
              access: 'ml-page',
            },
            {
              route: '/ml-ths-logs',
              icon: 'flaticon-coins',
              text: this.$t('AUTH.MENU.THS_LOGS'),
              access: 'ml-ths-logs',
            },
            {
              route: '/ml-ths-instances',
              icon: 'flaticon-coins',
              text: this.$t('AUTH.MENU.THS_INSTANCES'),
              access: 'ml-ths-instances',
            },
            {
              route: '/ml-sales',
              icon: 'flaticon-coins',
              text: this.$t('COMMON.SALES'),
              access: 'ml-sales',
            },
            {
              route: '/ml-sales-kanban',
              icon: 'flaticon-coins',
              text: this.$t('COMMON.SALES_KANBAN'),
              access: 'ml-sales-kanban',
            },
            
            {
              route: '/ml-support-admin',
              icon: 'flaticon-coins',
              text: this.$t('AUTH.MENU.SUPPORT_ADMIN'),
              access: 'ml-ths-instances',
            },
            {
              route: '/ml-customers',
              icon: 'flaticon-coins',
              text: this.$t('AUTH.MENU.CUSTOMERS'),
              access: 'ml-customers',
            },
          ],
        },
      ],

      access: {}
    }
  },
  mounted() {

    for (let i = 1; i < this.menuSections.length; ++i) {
      this.menuSections[i].items.sort((a, b) => {
        return a.text < b.text ? -1 : 1;
      });
    }

    this.menuSections = [ ...this.menuSections ];

    for (const section of this.menuSections) {
      const access = section.items.filter(i => this.hasAccess(i.access));

      if (access.length === 0) {
        section.hide = true;
      }
    }
    
  },
  watch: {
    currentAccess(newValue, oldVal) {
    },
  },
  methods: {
    show_notes() {
      this.$refs['notes_modal'].show();
    },
    hasAccess(page) {
      return hasAccessToPageForCurrentCompany(page);
    },
    hasActiveChildren(match) {
      return this.$route['path'].indexOf(match) !== -1;
    },
    section(name){
      let list = [];
      switch (name) {
        case 'members':
          list = ['ml-member-editor', 'ml-members-control', 'ml-advanced-search', 'ml-import', 'ml-stickprov', 'ml-ladok', 'ml-properties', 'ml-renew-page', 'ml-payments'];
          break;
        case 'association':
          list = ['ml-mucf', 'ml-settings', 'ml-registersetup', 'ml-periods', 'ml-users', 'ml-subcompany', 'ml-kvitto', 'ml-files', 'ml-shopitem', 'ml-book', 'ml-importcompanies', 'ml-transactions'];
          break;
        case 'communication':
          list = ['ml-sendout', 'ml-templates'];
          break;
        case 'admin':
          list = ['ml-billing','ml-customers'];
          break;
        default :
          break;
      }

      for (let i = 0; i < list.length; i++){
        if (this.hasAccess(list[i]))
          return true
      }
      return false;
    },
  }
};
</script>
